
const save = (key, value)=>{
    // convert objects to string
    value = typeof(value) == 'object' ? JSON.stringify(value): value;
    // validate boolean 
    if(typeof(value) == 'boolean'){
        sessionStorage.setItem(`${key}-0`, value);
        return;
    }
    //split value into chunks
    value = value.match(/.{1,100000}/g);
    // store every chunk
    value.forEach((chunk, index) => {
        sessionStorage.setItem(`${key}-${index}`, chunk);
    });    
}

const load = (key)=>{
    return new Promise(async (resolve, reject)=>{
        let response = '';
        let finished = false;
        let chunk;
        let index = 0;
        while(!finished){
            chunk = await sessionStorage.getItem(`${key}-${index}`);
            if(chunk == undefined){
                finished = true;
                try {
                    resolve(JSON.parse(response));
                } catch (error) {
                    resolve(response);
                }
                break;
            }
            response += chunk;
            index++;
        }
    })
}

const cloud = 'https://sanolivar.com/api/atom.php?tk=apiuser';
const cloudStorage = {
    getUserInfo: (email, callback)=>{
        fetch(cloud+`&op=>get&m=${email}`)
            .then(r=> r.json())
            .then(json=> callback(json));
    },
    insertUser: async (email, tyc, mailing)=>{
        const url = `${cloud}&op=insert&m=${email}&t=${tyc}&r=${mailing}`;
        fetch(url)
            .then(r=> r.json());
    },
    updateUser: (email, name)=>{
        fetch(cloud+`&op=>update&m=${email}&n=${name}`)
            .then(r=> r.json())
            .then(json=> callback(json));
    },
    storeMessage: (messages, feeling)=>{
        messages = JSON.stringify(messages);
        // retrieve user email
        load('user_email').then(response => {
            const data = {
                m: messages,
                f: feeling,
                u: response
            }
            fetch(cloud,{
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            })
            .then(r=> r.json());
        })
    }
}

export {
    load,
    save,
    cloudStorage
}