import { useState, useEffect } from 'react';
import './App.css';
import Avatar from './core/Avatar.jsx';
import { updateAnimation, updateEmoji } from './core/Avatar.jsx';
import { initAI, reset, setAIContext } from './libs/OpenAI.js';
import { VoiceManager } from './libs/VoiceEngine.js';

// import model assets
import { animations } from './core/Model.js';
// import languages
import { dialogs, transLangs } from './core/Lang.js';
// weather, financial and news
import { weatherRequest, financialRequest } from './libs/Services.js';
// get current location coordinates
let coords;

// voice engine instance
const VoiceEngine = new VoiceManager();

// root assets folder
const assetsRoot = "/atom-assets/";

// path to model file
const modelFile = `${assetsRoot}atom-modern.glb`;
// default emoji root
const emojiRoot = `${assetsRoot}logo.png`;
// default emoji root 
const envRoot = `${assetsRoot}environment.hdr`;

let synthQueue, setSynthQueue;

// default lang
let lang = 'es';
// avatar name
const NOMBRE = ['atom', 'adam', 'átomo', 'attends', 'atún'];
// words that can match avatar name (excluded)
const exclusions = ['ella', 'toma', 'heaton', 'átomo', 'tom', 'adam'];
// activation click
let firstClick = true;

const diccionarioUso = ['sanolivar','zerto', 'serto', 'certo','tenjo','tenjo','tenjo','tenjo'];

// voices matching Atom voice
const loadVoices = async () => {
  // first attempt to get speech synthesis voices (theres no way to make it asynchronously)
  speechSynthesis.getVoices();
  // second attempt
  setTimeout(() => {
    const voices = {};
    const spvc = speechSynthesis.getVoices();

    // store voices for every language
    for (let v of spvc) {
      if (v.name.includes('Alex') && v.lang.includes('es-PE')) voices.es = v;
      if (v.name.includes('Christopher') && v.lang.includes('en')) voices.en = v;
      if (v.name.includes('Henri') && v.lang.includes('fr')) voices.fr = v;
      if (v.name.includes('Giuseppe') && v.lang.includes('it')) voices.it = v;
      if (v.name.includes('Florian') && v.lang.includes('de')) voices.de = v;
      if (v.name.includes('YunJhe') && v.lang.includes('zh')) voices.zh = v;
      if (v.name.includes('Keita') && v.lang.includes('ja')) voices.ja = v;
    }
    // configure voice engine
    VoiceEngine.config(lang, NOMBRE, exclusions, voices, tools, dialogs[lang], diccionarioUso);
  }, 500);
}


// change language function
const langchange = (param) => {
  // recognize the target language
  let target;
  for (let code of Object.keys(transLangs)) {
    for (let lang of transLangs[code]) {
      if (param.toLowerCase().includes(lang)) target = code;
    }
  }
  if (target == undefined) return "Lo siento, 😢 no identifico el idioma que deseas.";
  // no reference assignement
  lang = target.toString();
  loadVoices();
  return "El idioma ha sido cambiado 📖";
}

const tempRequest = async (param) => {
  coords = navigator.geolocation.getCurrentPosition(pos => coords = pos.coords);
  const freq = param.includes('actual') ? 'actual' : 'diario';
  const target = param.includes('en') ? param.split('en')[1].replaceAll(' ', '').replaceAll('?', '') : null;
  const response = await weatherRequest(
    target != null ? target : coords.longitude,
    target != null ? null : coords.latitude,
    freq
  );
  let res = await predict("Dada esta informacion que puedes decirme del clima: " + JSON.stringify(response), lang, r => { console.log(r) });
  return await res;
}

const exchanges = ['dólar', 'dolar', 'bitcoin', 'euro', 'café', 'petróleo'];
const financial = async (param) => {
  return new Promise(async (resolve, reject) => {
    let target;
    exchanges.forEach(ex => {
      if (param.includes(ex)) target = ex;
    })
    if (target == undefined) resolve("Lo siento, 😢 no identifico el valor que deseas.");
    let func = target == "café" ? "COFFEE" : target == 'petróleo' ? 'BRENT' : param.includes('actual') ? 'actual' : 'semanal';
    let data = await financialRequest(func, target.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""), 'peso');
    let tipo = param.includes('predice') || param.includes('predecir') ? 'En base a esta informacion podrias predecir de manera resumida, su comportamiento las proximas dos semanas? ' : 'Podrias brindarme un analisis muy resumido de esta informacion?';
    predict(tipo + JSON.stringify(await data), lang, r => { resolve(r) });
  })
}

// custom avatar functions
const tools = {
  "hora es": () => {
    const date = new Date();
    let hora = date.getHours();
    hora = hora > 12 ? hora - 12 : hora;
    const ini = hora > 1 ? "Son las ⌚ " : "Es la ⌚ ";
    return "Hola, claro que sí! " + ini + hora + ':' + date.getMinutes();
  },
  "time is it": () => {
    const date = new Date();
    let hora = date.getHours();
    hora = hora > 12 ? hora - 12 : hora;
    return "Hi, for sure! It's ⌚ " + hora + ':' + date.getMinutes();
  },
  "fecha es": () => {
    return "Hola, por supuesto, hoy es: 📆" + new Date().toLocaleDateString('es-CO', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  },
  "fecha actual": () => {
    return "Hola, por supuesto, hoy es: 📆" + new Date().toLocaleDateString('es-CO', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  },
  "date today": () => {
    return "Hi, today date is: 📆" + new Date().toLocaleDateString('en-US', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  },
  "today's date": () => {
    return "Hi, today date is: 📆" + new Date().toLocaleDateString('en-US', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  },
  // "precio del": financial,
  // "precio actual": financial,
  // "predecir el precio": financial,
  // "clima": tempRequest,
  // "temperatura": tempRequest,
  "cambiar el idioma": langchange,
  "cambia el idioma": langchange,
  "cambiar idioma": langchange,
  "cambia idioma": langchange,
  "cambia tú idioma": langchange,
  "change language": langchange,
  "change your language": langchange,
  "changer de langue": langchange,
  "cambiare lingua": langchange,
  "sprache ändern": langchange,
  "改變語言": langchange,
  "言語を変更する": langchange,
}


// this regex matches animation commands in format [command/] and emojis;
const regex = /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff]|(?:\[.*?\/\]))/gm;

// OpenAi contexts
let context, setContext;

let init, setInit;

export const changeStatus = (newStatus)=>{
  if(setInit != undefined) setInit(newStatus);
}

/**
 * MAIN APP
 * Avatar Application with 3d animated avatar, speech recognition and sintesis
 */
export default ({predict, lstn}) => {
  loadVoices();
  // [init, setInit] = useState(false);
  const [engineOk, setEngineOk] = useState(true);
  const [avatarStatus, setAvatarStatus] = useState(false); // false listening true talking null turnedoff
  const [infFin, setInfFin] = useState(true);
  
  // voice synthesis queue
  [synthQueue, setSynthQueue] = useState([]);

  // ai context
  [context, setContext] = useState('sanolivar');

  setTimeout(() => {
    setEngineOk(true);
  }, 100);


  useEffect(()=>{
    // if(!init) return;
    if(!lstn) return;
    if(avatarStatus == true || synthQueue.length == 0) return;
    // retrieve commands
    const commands = synthQueue[0].match(regex);
    // split sentences by commands and emojis
    let splitted = synthQueue[0].split(regex);
    // clean string chunks
    splitted.forEach((chunk, i) => {
      splitted[i] = chunk.replace(regex, "");
    });
    splitted.forEach((chunk, i) => {
      if (chunk == "") splitted.splice(i, 1)
    });

    setAvatarStatus(true);
    // loop through all chunks
    let index = 0;
    const recallSynth = () => {
      // init speaker
      if (index == 0) setTimeout(() => {
        if(synthQueue.length <= 1) updateAnimation('iniciar_habla', 'hablar');
      }, 800);
      VoiceEngine.play(splitted[index], lang, () => {
        if(commands == null || index >= commands.length){
          if(synthQueue.length > 0){
            setAvatarStatus(false);
            // remove last called element
            let tmp = JSON.parse(JSON.stringify(synthQueue))
            tmp.shift();
            setSynthQueue(tmp);
            if(tmp.length == 0){
              VoiceEngine.stop();
              updateAnimation('detener_habla','idle');
              setAvatarStatus(false);
            }
            return;
          }
        } else {
          let newAnim = commands[index];
          if(newAnim == undefined){  
            index++;
            return recallSynth();
          }
          // remove special characters
          newAnim = newAnim.replace(/[\[\]\/]/g, '');
          // dispatch command
          if (newAnim.length > 2) updateAnimation(newAnim, 'hablar');
          // dispatch emoji
          else updateEmoji(newAnim);
          index++;
          return recallSynth();
        }
      })
    }
    recallSynth();

  },[synthQueue, infFin, avatarStatus])

  /**
   * Callback after speech recognition results
   * @param {string} recoResult Result of speech recognition
   */
  const recoCallback = (recoResult) => {
    // request chatbot answer
    predict(recoResult, lang, pred =>{
      setTimeout(() => {
        let tmp = JSON.parse(JSON.stringify(synthQueue))
        tmp.push(pred);
        setSynthQueue(tmp);
      }, 100);
    });
  }

  /**
   * Function called when the Avatar is listening us
   */
  const listeningCallback = () => {
    // change animation to listening
    updateAnimation('oir_atentamente', 'idle');
    if(synthQueue.length > 0) setSynthQueue([]);
    setAvatarStatus(false);
  }

  useEffect(() => {
    if (!lstn) {
      if (engineOk) updateAnimation('idle');
      VoiceEngine.stopEngine();
      // only as component unmount Avatar
      setEngineOk(false);
    } else {
      // mount avatar
      setEngineOk(true);
      // change icon to listening
      setAvatarStatus(false);
      // launch voice recognition for the first time
      VoiceEngine.activeListening(recoCallback, listeningCallback, (arg, finished) => {
        setTimeout(() => {
          let tmp = JSON.parse(JSON.stringify(synthQueue))
          tmp.push(arg);
          setSynthQueue(tmp);
          setInfFin(finished);
        }, 100);
      });
    }
  }, [lstn]);

  if(!engineOk) return (<div></div>);
  else return (
    <Avatar
      active={lstn}
      model={modelFile}
      environment={envRoot}
      defaultAnim={'idle'}
      defaultEmoji={emojiRoot}
      animationRefs={animations}
      face={'face'}
      altFace={'panel_1'}
    />
  )
}